import {appDomainUrl, apiDomainUrl} from './domains'

export const appDomain = appDomainUrl
export const apiDomain = apiDomainUrl
export const ENDPOINTS = {

  chat: {
    resource: `${apiDomain}chat/`,
    unreadMessages: `${apiDomain}unread-messages/`
  },

  faq: {
    public_faq: `${apiDomain}get-faq/`,
    resource: `${apiDomain}faq/`
  },

  games: {
    resource: `${apiDomain}games/`,
    gamesWithMentors: `${apiDomain}games-with-mentors/`,
    heroGames: `${apiDomain}hero-games/`
  },

  mentoring: {
    resource: `${apiDomain}mentoring/`,
    updateMentoring: `${apiDomain}update-mentoring`,
    updateOrer: `${apiDomain}update-order`,
    uploadBanner: `${apiDomain}upload-banner`
  },

  notifications: {
    resource: `${apiDomain}notifications/`
  },

  purchases: {
    apply_coupon: `${apiDomain}apply-coupon`,
    checkout: `${apiDomain}checkout/`,
    finish_purchase: `${apiDomain}finish-purchase/`,
    profile_purchases: `${apiDomain}get-profile-purchase/`,
    resource: `${apiDomain}purchases/`
  },

  scheduleAvailability: {
    resource: `${apiDomain}schedule-availability`,
    find_schedule: `${apiDomain}find-schedule-availability`
  },

  users: {
    check_recovery_pass_code: `${apiDomain}check-recovery-password-code`,
    findProfile: `${apiDomain}find-profile`,
    forgot_password: `${apiDomain}forgot-password`,
    login: `${apiDomain}login`,
    register: `${apiDomain}register-user`,
    resource: `${apiDomain}users/`,
    saveNewPassword: `${apiDomain}update-password/`,
    updateBanner: `${apiDomain}update-banner`,
    updateAvatar: `${apiDomain}update-avatar`
  }

}
