import axios from 'axios'
import {getBearer} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    purchases: []
  },
  mutations: {
    setPurchases (state, purchases) {
      state.purchases = purchases
    }
  },
  actions: {

    applyCoupon (context, params) {
      return axios.post(ENDPOINTS.purchases.apply_coupon, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(err => {
          return err.response
        })
    },

    checkout (context, params) {
      return axios.post(ENDPOINTS.purchases.checkout, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(err => {
          return err.response
        })
    },

    getPurchases ({commit}, params) {
      return axios.get(ENDPOINTS.purchases.resource, {params, headers: getBearer()})
        .then(resp => {
          if (resp.status === 200) {
            let purchases = resp.data
            commit('setPurchases', purchases)
            return purchases
          }
          return resp
        })
    },

    getProfilePurchases ({commit}, params) {
      return axios.get(ENDPOINTS.purchases.profile_purchases, {params, headers: getBearer()})
        .then(resp => {
          if (resp.status === 200) {
            let purchases = resp.data
            commit('setPurchases', purchases)
            return purchases
          }
          return resp
        })
    },

    finishPurchase (context, params) {
      return axios.post(ENDPOINTS.purchases.finish_purchase, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(err => {
          return err.response
        })
    }

  }
}
