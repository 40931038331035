import axios from 'axios'
import {getBearer} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    hasUnreadMessage: false
  },
  mutations: {
    setUnreadMessageStatus (state, status) {
      state.hasUnreadMessage = status
    }
  },
  actions: {
    unreadMessages ({commit}) {
      return axios.get(ENDPOINTS.chat.unreadMessages, {headers: getBearer()})
        .then(resp => {
          if (resp.status === 200) {
            commit('setUnreadMessageStatus', resp.data.status)
          }
        })
    },
    getConversations (context, params) {
      return axios.get(ENDPOINTS.chat.resource, {params, headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(err => {
          return err.response
        })
    },
    sendMessage (context, params) {
      return axios.post(ENDPOINTS.chat.resource, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(err => {
          return err.response
        })
    }
  }
}
