import axios from 'axios'
import {getBearer} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    notifications: []
  },
  mutations: {
    setNotifications (state, notifications) {
      state.notifications = notifications
    }
  },
  actions: {
    getNotifications ({commit}) {
      return axios.get(ENDPOINTS.notifications.resource, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setNotifications', resp.data)
        }
      })
    },
    updateNotification ({commit}, params) {
      return axios.put(ENDPOINTS.notifications.resource + params.id, params, {headers: getBearer()})
        .then(resp => {
          if (resp && resp.status === 200) {
            commit('setNotifications', resp.data)
          }
          return resp
        }).catch(error => {
          return error
        })
    }
  }
}
