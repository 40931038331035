import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    component: () => import('../Base.vue'),
    meta: {
      requires_auth: false
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/website-pages/Home.vue'),
        meta: {
          requires_auth: false
        },
      },
      {
        path: '/mentors',
        name: 'mentors',
        component: () => import('../views/website-pages/Mentors.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/mentors/:game',
        name: 'mentorsByGame',
        component: () => import('../views/website-pages/Mentors.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/mentoring',
        name: 'mentoringByMentors',
        component: () => import('../views/website-pages/mentoring/Mentoring.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/mentoring/new',
        name: 'createMentoring',
        component: () => import('../views/website-pages/mentoring/Create.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/mentoring/edit',
        name: 'editMentoring',
        component: () => import('../views/website-pages/mentoring/Edit.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/profile/:user',
        name: 'profile',
        component: () => import('../views/website-pages/Profile.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/profile/:user/:mentoring',
        name: 'profileWithMentoring',
        component: () => import('../views/website-pages/Profile.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/games',
        name: 'games',
        component: () => import('../views/website-pages/Games.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/config',
        name: 'config',
        component: () => import('../views/website-pages/Configs.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/config/:tab',
        name: 'configTab',
        component: () => import('../views/website-pages/Configs.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/config/:tab/:id',
        name: 'configTabId',
        component: () => import('../views/website-pages/Configs.vue'),
        meta: {
          requires_auth: true
        }
      },

      // extras pages
      {
        path: '/terms',
        name: 'terms',
        component: () => import('../views/website-pages/Terms.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/website-pages/Privacy.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/cookies',
        name: 'cookies',
        component: () => import('../views/website-pages/Cookies.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/support',
        name: 'support',
        component: () => import('../views/website-pages/Support.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/website-pages/FAQ.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/faq/:topic',
        name: 'faqByTopic',
        component: () => import('../views/website-pages/FAQ.vue'),
        meta: {
          requires_auth: false
        }
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import('../views/website-pages/Chat.vue'),
        meta: {
          requires_auth: true
        },
      },
      {
        path: '/chat/:mentor',
        name: 'chatWithMentor',
        component: () => import('../views/website-pages/Chat.vue'),
        meta: {
          requires_auth: true
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/website-pages/Notifications.vue'),
        meta: {
          requires_auth: true
        },
      }
    ]
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/website-pages/Checkout.vue'),
    meta: {
      requires_auth: true
    }
  },
  {
    path: '/checkout/:status',
    name: 'checkoutFinished',
    component: () => import('../views/website-pages/Checkout.vue'),
    meta: {
      requires_auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth-pages/LoginPage.vue'),
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth-pages/RegisterPage.vue'),
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassoword',
    component: () => import('../views/auth-pages/ForgotPassword.vue'),
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/errors/NotFound.vue'),
    meta: {
      requires_auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
