export function getBearer () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return {'Authorization': 'Bearer ' + authUser.access_token}
  } else {
    getHeaderPublic()
  }
}

export const getHeaderPublic = function () {
  return {'Accept': 'application/json'}
}

export const getHeaderPublicFormData = function () {
  return {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}
}

export const getHeaderFile = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
  if (tokenData) {
    return {
      'Accept': 'application/json, image/*, file/*, .avi, video/*, application/octet-stream, application/*, .zip, .rar, .csv, .crt',
      'Authorization': 'Bearer ' + tokenData.access_token
    }
  } else {
    getHeaderPublic()
  }
}

export const _roles = {
  adm: 1,
  player: 2
}

export const _roles_name = [
  'ADM', 'Player'
]
