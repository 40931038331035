import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'
import {apiDomainUrl} from '@/config/domains'

import alertStore from '@/store/alertStore'
import chatStore from '@/store/chatStore'
import faqStore from '@/store/faqStore'
import gameStore from '@/store/gameStore'
import mentoringStore from '@/store/mentoringStore'
import notificationStore from '@/store/notificationStore'
import purchaseStore from '@/store/purchaseStore'
import userStore from '@/store/userStore'

axios.defaults.baseURL = apiDomainUrl

export default new Vuex.Store({
  modules: {
    alertStore,
    chatStore,
    faqStore,
    gameStore,
    mentoringStore,
    notificationStore,
    purchaseStore,
    userStore
  }
})
