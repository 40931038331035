import axios from 'axios'
import {getBearer, getHeaderFile} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    filterApplied: false,
    user: null,
    users: []
  }, mutations: {
    setUserData (state, image) {
      state.user = image
    },
    setUsers (state, users) {
      state.users = users
    },
    setFilterApplied (state, status) {
      state.filterApplied = status
    }
  }, actions: {

    setUserData ({commit}, data) {
      commit('setUserData', data)
    },

    getUsers () {
      return axios.get(`${ENDPOINTS.users.resource}`, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    login ({commit}, params) {
      return axios.post(ENDPOINTS.users.login, params, {}).then(resp => {
        if (resp.status === 200) {
          commit('setUserData', resp.data.user)
          window.localStorage.setItem('authUser', JSON.stringify(resp.data))
        }
        return resp
      }).catch(error => {
        return error
      })
    },

    registerUser ({commit}, params) {
      return axios.post(ENDPOINTS.users.register, params, {headers: getBearer()})
        .then(resp => {
          if (resp.status === 200) {
            commit('setUserData', resp.data.user)
            window.localStorage.setItem('authUser', JSON.stringify(resp.data))
          }
          return resp
        }).catch(resp => {
          return resp.response
        })
    },

    forgotPassword (context, params) {
      return axios.post(ENDPOINTS.users.forgot_password, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

    checkRecoveryPasswordCode (context, params) {
      return axios.post(ENDPOINTS.users.check_recovery_pass_code, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error.response
      })
    },

    updatePassword (context, params) {
      return axios.put(`${ENDPOINTS.users.saveNewPassword}${params.user_id}`, params, {})
        .then(resp => {
          return resp.data
        }).catch(error => {
          return error.response
        })
    },

    getUserData (response, id) {
      return axios.get(`${ENDPOINTS.users.resource}${id}`, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    findProfile (response, params) {
      return axios.get(`${ENDPOINTS.users.findProfile}`, {params, headers: getBearer()})
        .then(resp => {
          return resp
        })
    },

    updateConfig ({commit}, params) {
      return axios.put(`${ENDPOINTS.users.resource}${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          commit('setUserData', resp.data)
          let authUser = JSON.parse(window.localStorage.getItem('authUser'))
          authUser.user = resp.data
          window.localStorage.setItem('authUser', JSON.stringify(authUser))
          return resp
        }).catch(error => {
          return error.response
        })
    },

    update (context, params) {
      return axios.put(`${ENDPOINTS.users.resource}${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    destroy (context, id) {
      return axios.delete(`${ENDPOINTS.users.resource}${id}`, {headers: getBearer()})
        .then(resp => {
          return resp
        })
    },

    updateBanner (context, params) {
      return axios.post(`${ENDPOINTS.users.updateBanner}`, params, {headers: getHeaderFile()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    updateAvatar (context, params) {
      return axios.post(`${ENDPOINTS.users.updateAvatar}`, params, {headers: getHeaderFile()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    setScheduleAvailability (context, params) {
      return axios.post(`${ENDPOINTS.scheduleAvailability.resource}`, params, {headers: getHeaderFile()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error.response
        })
    },

    findScheduleAvailability () {
      return axios.get(`${ENDPOINTS.scheduleAvailability.find_schedule}`, {headers: getBearer()}).then(resp => {
        return resp.data
      })
    }

  }
}
