import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugin/filters'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VCalendar from 'v-calendar'
import './registerServiceWorker'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    'pt-PT': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY-MM-DD'
      }
    }
  }
});

Vue.use(CKEditor)

import axios from 'axios'

import VueResource from 'vue-resource'
import './config/registerServiceWorker'
// import {_roles} from '@/config/config'
// import {rolePermission} from '@/config/routesSystem'

Vue.use(VueResource)

Vue.config.productionTip = true

let path = '/'

router.beforeEach((to, from, next) => {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (to.meta.requires_auth) {
    if (!authUser || (authUser && !authUser.user)) {
      window.localStorage.clear()
      next('/login')
      return false
    } else if (to.path === '/') {
      next('/')
      return false
    }
  }
  path = to.path
  next()
  window.scrollTo({
    left: 0,
    top: 0
  })
})

router.afterEach((to) => {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (to.meta.requires_auth && authUser) {
    store.dispatch('unreadMessages')
  }
})

axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (error.response.data.error === 'invalid_token' && path !== 'login') {
            window.localStorage.clear()
            window.location = '/'
            break
          }
          break
        case 418:
          window.localStorage.clear()
          window.location = '/'
          break
      }
      return Promise.resolve(error.response)
    } else {
      if (error.code === "ERR_NETWORK") {
        return {status: 500}
      }
    }
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
