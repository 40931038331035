import axios from 'axios'
import {getBearer} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    mentoringList: []
  },
  mutations: {
    setMentoringList (state, mentoring) {
      state.mentoringList = mentoring
    }
  },
  actions: {
    indexMentoring (context, id) {
      return axios.get(`${ENDPOINTS.mentoring.resource}${id}`, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    storeMentoring (context, params) {
      return axios.post(ENDPOINTS.mentoring.resource, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    updateMentoring (context, params) {
      return axios.post(ENDPOINTS.mentoring.updateMentoring, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    updateMentoringOrder (context, params) {
      return axios.post(ENDPOINTS.mentoring.updateOrer, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    deleteMentoring (context, id) {
      return axios.delete(`${ENDPOINTS.mentoring.resource}${id}`, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    }
  }
}
