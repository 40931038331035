import Vue from 'vue'
import moment from 'moment'
import Inputmask from 'inputmask'

Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('DD/MM/YYYY') : ''
})

Vue.filter('formatHour', function (hour) {
  return hour ? moment(hour).format('HH:mm:ss') : ''
})

Vue.filter('formatDatetime', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''
})

Vue.filter('formatDatetimeWithoutSeconds', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : ''
})

Vue.filter('shortName', function (name) {
  return name ? name.split(' ')[0] : ''
})

Vue.filter('currence', function (value) {
  if (value == null) {
    return value
  } else {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    } else {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    }
  }
})

Vue.filter('currenceCheckout', function (value) {
  if (value == null || value === 0) {
    return 'Grátis'
  } else {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    } else {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    }
  }
})

Vue.filter('formatPhone', function (value) {
  if (value) {
    value = value.replace('.', '').replace('-', '').replace('.', '').replace('(', '').replace(')', '').replace(' ', '')
    if (value.length === 11) {
      value = '(' + value.substr(0, 2) + ') ' + ' ' + value.substr(2, 1) + ' ' + value.substr(3, 4) + '-' + value.substr(7, 6)
      return value
    } else if (value.length === 10) {
      value = '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(6, 6)
      return value
    } else {
      return value
    }
  }
})

Vue.filter('truncateStr', function (string, sizeStr = 20) {
  if (string && string.length >= sizeStr) {
    return string.substring(0, sizeStr) + '...'
  } else {
    return string
  }
})

Vue.directive('mask', (el, binding) => {
  const {value} = binding
  return Inputmask({mask: value, keepStatic: true, placeholder: ''}).mask(el)
})

Vue.mixin({
  methods: {
    mentoringWorkload (classQuantity, classTime) {
      if (classQuantity && classTime) {
        let workloadMinutes = classQuantity * classTime
        let hours = Math.floor(workloadMinutes / 60)
        let minutes = workloadMinutes % 60
        if (minutes > 0) {
          return `${hours}h${minutes}min`
        } else {
          return hours + 'h'
        }
      } else {
        return ''
      }
    },
    formatCpf (str) {
      if (str) {
        str = str.replace(/\D+/g, '')
        str = str.trim()
        return (str.substr(0, 3) + '.' + str.substr(3, 3) + '.' + str.substr(6, 3) + '-' + str.substr(9, 2))
      }
      return str
    },
    mask (elementName, mask) {
      let element = document.getElementsByName(elementName)[0]
      Inputmask(mask).mask(element, {greedy: false})
    },
    // setDataId
    routeTo (route, id) {
      window.localStorage.setItem(route.split('/')[0], id)
      this.$router.push(`/${route}`)
    },
    shareContent (title, text, url) {
      if (navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: url
        })
      }
    },
    getUser () {
      try {
        return JSON.parse(window.localStorage.getItem('authUser')).user
      } catch (e) {
        return false
      }
    },
    getToken () {
      try {
        return JSON.parse(window.localStorage.getItem('authUser')).access_token
      } catch (e) {
        return false
      }
    }
  }
})
