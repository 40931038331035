const host = window.location.host
const hostname = window.location.hostname
const isProduction = process.env.NODE_ENV === 'production'

let appDomainUrl = 'http://' + host + '/'
let apiDomainUrl = 'http://' + hostname + ':8000/api/'
let apiDomainPublic = 'http://' + hostname + ':8000/'

if (isProduction) {
  appDomainUrl = `https://${host}/`
  apiDomainUrl = `https://api.${host}/api/`
  apiDomainPublic = `https://api.${host}/`
}

export {appDomainUrl, apiDomainUrl, apiDomainPublic}
