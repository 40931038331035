import axios from 'axios'
import {getBearer, getHeaderPublic} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  state: {
    games: [],
    heroGames: []
  },
  mutations: {
    setGames (state, games) {
      state.games = games
    },
    setHeroGames (state, games) {
      state.heroGames = games
    }
  },
  actions: {
    getGames ({commit}, params) {
      return axios.get(ENDPOINTS.games.resource, {params, headers: getHeaderPublic()}).then(resp => {
        if (resp.status === 200) {
          let games = resp.data
          commit('setGames', games)
          return games
        }
      })
    },
    getGamesWithMentors () {
      return axios.get(ENDPOINTS.games.gamesWithMentors, {headers: getHeaderPublic()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    updateGame ({commit}, params) {
      return axios.put(ENDPOINTS.games.resource + params.id, params, {headers: getBearer()})
        .then(resp => {
          if (resp && resp.status === 200) {
            commit('setGames', resp.data)
          }
          return resp
        }).catch(error => {
          return error
        })
    },
    getHeroGames ({commit}) {
      return axios.get(ENDPOINTS.games.heroGames, {headers: getHeaderPublic()})
        .then(resp => {
          if (resp && resp.status === 200) {
            commit('setHeroGames', resp.data)
          }
          return resp
        }).catch(error => {
          return error
        })
    }
  }
}
