import axios from 'axios'
import {getBearer} from '@/config/config'
import {ENDPOINTS} from '@/config/routes'

export default {
  actions: {
    getFaq (context, params) {
      return axios.get(ENDPOINTS.faq.resource, {params, headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    getPublicFaq (context, params) {
      return axios.get(ENDPOINTS.faq.public_faq, {params, headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    createFaq (context, params) {
      return axios.post(ENDPOINTS.faq.resource, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    },
    updateFaq (context, params) {
      return axios.put(ENDPOINTS.faq.resource + params.id, params, {headers: getBearer()})
        .then(resp => {
          return resp
        }).catch(error => {
          return error
        })
    }
  }
}
